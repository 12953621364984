<template>
  <div id="feedback-details">
    <div id="feedback-detail">
      <div class="feedback-title">离线留言详情</div>
      <div class="feedback-header">
        <span>提交时间 : <NTime>{{Time}}</NTime></span>
        <span>联系方式 : {{phone}}</span>
        <span>提交账号 : {{User}}</span>
        <span>所属学校 : {{school}}</span>
      </div>
      
      <div v-html="Htmls" class="feedback-cont"></div>
      <div class="feedback-imgs">
        <img :src="item.file_url" v-for="item in Urls" :key="item.id" />
      </div>
      <PageLoading :loading="loading" />
    </div>
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h } from "vue";
import { useMessage, NTime } from "naive-ui";

import { resStatusEnum } from "@/enumerators/http.js";
import { GetBannerDetail } from "@/api/feedback.js";  
import { toRaw } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router';
import PageLoading from "@/components/PageLoading/index.vue";

const message = useMessage();
const { SUCCESS } = resStatusEnum;
const loading = ref(false);
const isOne = ref(true);  // 数据是否初始化
const route = useRoute();
const router = useRouter();
const ID = route.query.id;


 // 返回事件
const handleBack = () => {
  router.push('/problems/index/index');
};

const User = ref(null)
const Htmls = ref(null)
const phone = ref(null)
const Time = ref(null)
const Urls = ref(null)
const school = ref(null)

// 数据初始化
function updateList(data){
  loading.value = true;
  // 请求列表数据
  GetBannerDetail({"Feedback[id]":ID})
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Htmls.value = res.data.introduce
        Time.value = (Number(res.data.create_time) || 0) * 1000,
        Urls.value = res.data.upload_file
        phone.value = res.data.phone
        User.value = res.data.admin_username
        school.value = res.data.school_name
        isOne.value = false;
      }
    })
    .catch((reject) => {});
};
updateList();

</script>

<style scoped>
#feedback-details {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.feedback-header{
  color:#999
}
.feedback-header span{
  margin-right:15px
}
#feedback-detail{
  width: 1200px;
  background: #fff;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
.feedback-title{
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.feedback-cont{
  margin-top:20px;
  margin-bottom:20px
}
#feedback-details div img{
  max-width:1200px;
  margin-bottom:20px
}
.feedback-imgs{
  max-width: 100%;
}
</style>