import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

// 反馈列表
export function GetBannerIndex(data) {
  return request({
    url: '/backend/web/feedback/index',
    method: GET,
    params:data
  });
}

// 反馈详情
export function GetBannerDetail(data) {
  return request({
    url: '/backend/web/feedback/detail',
    method: GET,
    params:data
  });
}

// 获取所有学校列表
export function GetBannerSchoolIndex(data) {
  return request({
    url: '/backend/web/school/index',
    method: GET,
    params:data
  });
}

